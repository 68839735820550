<div
  class="w-full bg-gray-50"
  style="height: 100vh;"
>
  <main class="w-full max-w-xl mx-auto p-6">
    <div class="mt-7 bg-white border border-gray-200 rounded-xl shadow-sm">
      <div class="mx-6 my-4 p-4 sm:p-7">
        <div class="flex justify-center items-center">
          <a
            routerLink="/"
            href="#"
          >
            <img
              src="assets/elysator_logo.png"
              class=""
              width="250"
            >
          </a>
        </div>
        <div class="text-center mt-10">
          <h1 class="block text-2xl font-bold text-gray-800">Anmeldung</h1>
        </div>

        <div class="mt-5">
          <!-- Form -->
          <form
            (ngSubmit)="onSubmit()"
            [formGroup]="form"
          >
            <div class="grid gap-y-4">
              <!-- Form Group -->
              <div>
                <label
                  for="email"
                  class="block text-sm mb-2"
                >E-Mail</label>
                <div class="relative">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    formControlName="email"
                    autocomplete="email"
                    class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-elysator-blue focus:ring-elysator-blue disabled:opacity-50 disabled:pointer-events-none"
                    required
                    aria-describedby="email-error"
                  >
                  <div class="hidden absolute inset-y-0 end-0 pointer-events-none pe-3">
                    <svg
                      class="h-5 w-5 text-elysator-red"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                      aria-hidden="true"
                    >
                      <path
                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                      />
                    </svg>
                  </div>
                </div>
                <p
                  class="hidden text-xs text-elysator-red mt-2"
                  id="email-error"
                >Please include a valid email
                  address so we can get back to you</p>
              </div>
              <!-- End Form Group -->

              <!-- Form Group -->
              <div>
                <label
                  for="password"
                  class="block text-sm mb-2"
                >Passwort</label>
                <div class="relative">
                  <input
                    type="password"
                    id="password"
                    name="password"
                    formControlName="password"
                    autocomplete="password"
                    class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-elysator-blue focus:ring-elysator-blue disabled:opacity-50 disabled:pointer-events-none"
                    required
                    aria-describedby="password-error"
                  >
                  <div class="hidden absolute inset-y-0 end-0 pointer-events-none pe-3">
                    <svg
                      class="h-5 w-5 text-elysator-red"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                      aria-hidden="true"
                    >
                      <path
                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                      />
                    </svg>
                  </div>
                </div>
                <p
                  class="hidden text-xs text-elysator-red mt-2"
                  id="password-error"
                >8+ characters required</p>
              </div>
              <!-- End Form Group -->
              <p
                [ngClass]="!error ? 'hidden' : ''"
                class="text-sm text-center text-elysator-red mt-2"
                id="login-error"
              >{{ error
                }}</p>

              <button
                type="submit"
                class="my-3 w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-elysator-blue text-white hover:opacity-95 disabled:opacity-50 disabled:pointer-events-none"
              >Anmelden</button>

              <div class="flex justify-center items-center">
                <a
                  class="text-sm text-elysator-blue decoration-2 hover:underline font-medium"
                  routerLink="/password-forgotten"
                >Passwort vergessen?</a>
              </div>

            </div>
          </form>
          <!-- End Form -->
        </div>
      </div>
    </div>
  </main>
</div>
